<template>
    <div>
        <h2>จัดการคลัง/สาขา > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}คลัง/สาขา</h2>
        <v-card class="mt-4">
          <v-card-title>ประเภทช่องทาง</v-card-title>

          <v-card-text>
            <v-form v-model="isFormValid" @submit.prevent="submitForm">              
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อคลัง/สาขา *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col  cols="12" md="6">
                    <v-text-field 
                        v-model="formData.address"                         
                        label="ที่อยู่ *"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required, validators.maxLengthValidator(formData.address, 50)]"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                </v-col>                    
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense v-model="formData.city" 
                    placeholder="แขวง/ตำบล *" 
                    name="city" 
                    type="district" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>                                    
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.county" 
                    placeholder="เขต/อำเภอ *" 
                    name="county" 
                    type="amphoe" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.province" 
                    placeholder="จังหวัด *" 
                    name="province" 
                    type="province" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>                                  
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <VThailandAddressAutoComplete 
                    hide-details="auto" 
                    dense 
                    v-model="formData.postcode" 
                    placeholder="เลขไปรษณีย์ *" 
                    name="postcode" 
                    type="zipcode" 
                    @select="setAddress"
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    />
                </v-col>  
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                    <v-text-field 
                        v-model="formData.tel"                         
                        label="เบอร์ *"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[validators.required, validators.maxLengthValidator(formData.tel, 20)]"
                        :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                    ></v-text-field>
                </v-col>                    
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    outlined
                    v-model="formData.note"
                    label="หมายเหตุร้านค้า"
                    placeholder="หมายเหตุของร้านค้า *ลูกค้าไม่เห็นตรงนี้"
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-textarea>
                </v-col>              
              </v-row>
            </v-form>            
          </v-card-text>          

          <v-card-actions>              
            <v-btn
              color="primary"               
              large          
              :disabled="!isFormValid"
              :loading="sendingData"
              @click="submitForm"
              v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
            >
              {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
            </v-btn>

            <v-btn
              color="secondary"               
              outlined                 
              large
              :disabled="sendingData"
              @click="$router.go(-1)"
            >
              กลับ
            </v-btn>                
          </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { maxLengthValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPost, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        name: null,
        address: null,
        city: '',
        county: '',
        province: '',
        postcode: '',
        tel: null,
        note: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  async created() {
    const warehouse_id = this.$route.params.warehouse_id

    if (warehouse_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Inventory'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/inventory/warehouse/' + warehouse_id)

      this.formData = response
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      if (!this.isFormValid) return

      this.sendingData = true
      try {
        const warehouse_id = this.$route.params.warehouse_id
        if (warehouse_id == 'create') await asyncPost('/inventory/warehouse', this.formData)
        else {
          await asyncUpdate('/inventory/warehouse/' + warehouse_id, this.formData)
        }

        this.$router.push({ name: 'warehouse' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
    setAddress(address) {
      this.formData.city = address.district
      this.formData.county = address.amphoe
      this.formData.province = address.province
      this.formData.postcode = address.zipcode
    },
  },
  components: {},
}
</script>